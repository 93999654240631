import { useStaticQuery, graphql } from 'gatsby';

import translateContent from '@core/helpers/translation';

export default function allNodeSelectListQuery(
  language: string | undefined = process.env.GATSBY_DEFAULT_LANG
) {
  const {
    allTaxonomyTermArea,
    country,
    english,
    allNodeJobs
  } = useStaticQuery(graphql`
  query TaxonomyTermAreaQuery {
    allTaxonomyTermArea {
      edges {
        node {
          id: drupal_internal__tid
          name
          value: field_crm_id
          langcode
          relationships {
            children: taxonomy_term__area {
              id: drupal_internal__tid
              value: field_crm_id
              langcode
              collectionId: field_crm_field_collection_id
              name
              relationships {
                children: taxonomy_term__area {
                  id
                  field_crm_id
                  collectionId: field_crm_field_collection_id
                  name
                }
              }
            }
            parent {
              id: drupal_internal__tid
              name
              value: field_crm_id
              langcode
            }
          }
          collectionId: field_crm_field_collection_id
        }
      }
    }
    country: allTaxonomyTermCountry {
      edges {
        node {
          name
          countryCode: field_country_code
          crmId: field_crm_id
          langcode
        }
      }
    }
    english: allTaxonomyTermEnglishLevel {
      edges {
        node {
          crmId: field_crm_id
          langcode
          name
        }
      }
    }
    allNodeJobs: allNodeJob {
      edges {
        node {
          id
          title
          quantity: field_crm_quantity
          skills: field_crm_skills
          langcode
        }
      }
    }
  }
  `) || {};

  const taxonomyTermAreas = translateContent(allTaxonomyTermArea.edges ?? [], language);
  const taxonomyTermCountries = translateContent(country.edges ?? [], language);
  const taxonomyTermEnglish = translateContent(english.edges ?? [], language);
  const jobs = translateContent(allNodeJobs.edges ?? [], language);

  const areas = taxonomyTermAreas
    .filter(({ node }) => node?.relationships?.parent?.length === 0);

  const seniorities = taxonomyTermAreas
    .filter(({ node }) => {
      const { relationships } = node || {};

      if (relationships) {
        return relationships?.parent && relationships.parent.length > 0 && relationships.children;
      }

      return false;
    });

  const skills = taxonomyTermAreas
    .filter(({ node }) => {
      const { relationships } = node || {};

      if (relationships) {
        return relationships?.parent && relationships.parent.length > 0 && !relationships.children;
      }

      return false;
    });

  const countries = taxonomyTermCountries.filter((item) => {
    if (item?.node?.crmId) {
      return true;
    }

    return false;
  });

  const englishLevels = taxonomyTermEnglish.map((item) => item.node);

  return {
    areas,
    seniorities,
    skills,
    countries,
    englishLevels,
    jobs
  };
}
