import React, { FunctionComponent } from 'react';

const ConditionalRender: FunctionComponent<propTypes> = ({
  shouldRender,
  children,
  notAllowedReturn
}) => (shouldRender ? children : notAllowedReturn);

interface propTypes {
  shouldRender?: any;
  notAllowedReturn?: any;
  children:
    | React.ReactElement
    | undefined
    | false
    | string
    | undefined
    | Text;
}

ConditionalRender.defaultProps = {
  shouldRender: false,
  notAllowedReturn: null
};

export default ConditionalRender;
