import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { updateContact } from '@core/services/rootnet-service';
import translateContent from '@core/helpers/translation';

import allNodeDetailsOfNeedQuery from '@modules/qualifying_forms/steps/service/details-of-needs-query';
import { StepperContext } from '@modules/qualifying_forms/stepper-context';
import allNodeSelectListQuery from '@modules/apply_form/default-query';

import Checkbox from '@shared/checkbox/checkbox';

import { Graphql } from '@models/graphql';

import './styles.scss';

type EventTarget = {
  name: string;
  key: string;
  target: {
    id: string;
    name: string;
    value: string;
    href: string;
  };
};

type DropdownType = {
  type: string;
  label: string;
  selectedItems: Graphql[];
  checked: boolean;
};

export default function NewsletterForm(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const { countries } = allNodeSelectListQuery();
  const listsData = allNodeDetailsOfNeedQuery();
  const stepper = useContext(StepperContext);
  const [failedSend, setFailedSent] = useState<boolean>(false);
  const [state, setState] = useState({
    contactId: null,
    contactEmail: '',
    companyCountry: 0,
    contactName: '',
    contactLastName: '',
    contactPhoneCountry: 0,
    contactPhone: 0,
    contactRole: ''
  });
  const [isInvalid, setIsInvalid] = useState({
    contactEmail: false,
    companyCountry: false
  });
  const [categories, setCategories] = useState<DropdownType[]>([
    {
      type: 'solutions',
      label: t('Solutions'),
      selectedItems: [],
      checked: false
    },
    {
      type: 'technologies',
      label: t('Technologies'),
      selectedItems: [],
      checked: false
    },
    {
      type: 'expertiseAreas',
      label: t('Area of expertise'),
      selectedItems: [],
      checked: false
    }
  ]);

  useEffect(() => {
    setState({
      ...state,
      contactEmail: window.history.state?.payload?.email as string
    });
    Object.keys(listsData).map((key) => {
      const topicList = translateContent(
        listsData[key]?.edges,
        i18n.language
      ).map((content: Graphql) => content.node) as Graphql[];
      const tmpCategories = categories;
      const categoryIndex = categories.findIndex((value) => value.type === key);
      tmpCategories[categoryIndex].selectedItems = topicList;
      setCategories([...tmpCategories]);
      return topicList;
    });
    return () => {};
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    Object.keys(isInvalid).forEach((key) => (isInvalid[key] = false));
    if (state.contactId != null && state.contactId !== 0) {
      updateContactData();
    } else {
      isInvalid.contactEmail = state.contactEmail === '';
      isInvalid.companyCountry = state.companyCountry === 0;

      const allValid = Object.values(isInvalid).indexOf(true) === -1;

      if (!allValid) {
        setIsInvalid({ ...isInvalid });
      }
    }
  };

  const updateContactData = async () => {
    const contactBody: any = {
      contactEmail: state.contactEmail,
      name: state.contactName,
      last_name: state.contactLastName,
      phone: {
        country_id: state.contactPhoneCountry,
        value: state.contactPhone
      },
      role: state.contactRole,
      categories: categories
    };

    const SP = await updateContact(`${state.contactId}`, contactBody);
    if (SP) {
      stepper.changeStepper('SuccessStep');
    } else {
      setFailedSent(true);
      isInvalid.contactEmail = true;
      isInvalid.companyCountry = true;
      setIsInvalid({ ...isInvalid });
    }
  };

  const handleInputChange = (event: EventTarget) => {
    if (
      event.target.name === 'contactEmail' &&
      isInvalid.contactEmail === true
    ) {
      isInvalid.contactEmail = false;
    }
    if (
      event.target.name === 'companyCountry' &&
      isInvalid.companyCountry === true
    ) {
      isInvalid.companyCountry = false;
    }

    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const selectAllChange = (options: Graphql) => {
    const tmpCategories = categories;
    tmpCategories[String(options.key)].checked =
      !categories[String(options.key)].checked;
    tmpCategories[String(options.key)].selectedItems = categories[
      String(options.key)
    ].selectedItems?.map((item: Graphql) => ({
      ...item,
      checked: tmpCategories[String(options.key)].checked
    }));
    setCategories([...tmpCategories]);
  };

  const categoryItemChange = (options: Graphql) => {
    const tmpCategories = categories;
    tmpCategories[String(options.key)].selectedItems[
      String(options.id)
    ].checked =
      !tmpCategories[String(options.key)].selectedItems[String(options.id)]
        .checked;
    if (
      !tmpCategories[String(options.key)].selectedItems[String(options.id)]
        .checked
    ) {
      tmpCategories[String(options.key)].checked = false;
    } else {
      tmpCategories[String(options.key)].checked = !categories[
        String(options.key)
      ].selectedItems?.find((item: Graphql) => !item.checked);
    }
    setCategories([...tmpCategories]);
  };

  return (
    <BootstrapForm
      noValidate
      className="d-flex flex-column align-items-center mt-5"
      onSubmit={handleSubmit}
    >
      <div className="w-75 row gap-1 mx-auto">
        <BootstrapForm.Group className="mb-0 flex-grow-1">
          <BootstrapForm.Label className="">
            {t('COMPANY E-MAIL')}
            <span className="required ml-1">*</span>
          </BootstrapForm.Label>
          <BootstrapForm.Control
            isInvalid={isInvalid.contactEmail}
            name="contactEmail"
            onChange={(event) =>
              handleInputChange(event as unknown as EventTarget)
            }
            value={state?.contactEmail}
            defaultValue={state?.contactEmail}
          />
        </BootstrapForm.Group>

        <BootstrapForm.Group className="mb-0 flex-grow-1">
          <BootstrapForm.Label className="">
            {t('COUNTRY')}
            <span className="required ml-1">*</span>
          </BootstrapForm.Label>
          <BootstrapForm.Control
            as="select"
            isInvalid={isInvalid.companyCountry}
            name="companyCountry"
            onChange={(event) =>
              handleInputChange(event as unknown as EventTarget)
            }
            value={state?.companyCountry}
            defaultValue="false"
          >
            <option value="false">{t('Select an option')}</option>

            {countries.map(({ node }) => (
              <option key={`${node?.crmId}`} value={`${node?.crmId}`}>
                {node?.name}
              </option>
            ))}
          </BootstrapForm.Control>
        </BootstrapForm.Group>
      </div>

      <div className="category-section d-flex flex-column w-100 mt-5 pb-5">
        {categories.map((category, index) => (
          <div
            key={category.type}
            className={`category-container pt-5 ${index && 'mt-5'}`}
          >
            <div className="d-flex align-items-center gap-1">
              <p className="mb-0 h3 category-label text-marine">
                {category.label}
              </p>
              <Checkbox
                onValuesChange={selectAllChange}
                options={{
                  key: String(index),
                  title: 'All',
                  checked: category.checked
                }}
              />
            </div>
            <div className="row mt-4 row-gap-2">
              {category.selectedItems.map((categoryItem, categoryIndex) => (
                <div className="col-12 col-sm-6 col-lg-4" key={categoryItem.id}>
                  <Checkbox
                    options={{
                      ...categoryItem,
                      id: String(categoryIndex),
                      key: String(index)
                    }}
                    onValuesChange={categoryItemChange}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {failedSend && (
        <div className="justify-content-center mt-4 row">
          <h4 className="m-4">
            {t('Error has ocurred. Please contact support@rootstack.com')}
          </h4>
        </div>
      )}

      <button
        type="submit"
        className="btn btn-primary py-2 px-5 width-fit-content mt-5"
      >
        {t('Finish')}
      </button>
    </BootstrapForm>
  );
}
