import { useStaticQuery, graphql } from 'gatsby';

export default function allNodeDetailsOfNeedQuery() {
  const nodes = useStaticQuery(graphql`
    query allNodeDetailsOfNeed {
      solutions: allNodeSolution(filter: {field_weight: {gte: 0}}, sort: {fields: field_weight, order: DESC}) {
        edges {
          node {
            id
            title
            langcode
          }
        }
      }
      technologies: allNodeTechnology(sort: {fields: field_weight, order: DESC}) {
        edges {
          node {
            id
            title
            langcode
          }
        }
      }
      expertiseAreas: allNodeExpertiseArea(filter: {field_weight: {gte: 0}}, sort: {fields: field_weight, order: DESC}) {
        edges {
          node {
            id
            title
            langcode
          }
        }
      }
    }
  `);

  return nodes;
}
