import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import ConditionalRender from '@core/helpers/conditional-render';

import { Graphql } from '@models/graphql';

import './checkbox.scss';

type MultiSelectProps = {
  options: Graphql;
  onValuesChange: Function;
};

export default function Checkbox({
  options, onValuesChange
}: MultiSelectProps): React.ReactElement {
  return (
    <button className="d-flex btn btn-link checkbox-button p-0 gap-0.5" onClick={() => onValuesChange(options)} type="button">
      <div className={`checkbox-square ${options.checked ? 'active background-tangerine' : 'inactive'}`}>
        <ConditionalRender shouldRender={options.checked}>
          <FontAwesomeIcon icon={faCheck as IconProp} color="white" />
        </ConditionalRender>
      </div>
      <p className="mb-0 font-weight-normal text-align-left">{options.title}</p>
    </button>
  );
}
